.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    display: flex;
    flex: 1;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
  }
  
  main {
    flex: 1;
    padding: 20px;
  }
  