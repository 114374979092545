.footer {
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
  }
  

  
@media only screen and (max-width: 767px) {
  .footer{
      display: none;
  }
  
}

main {
  flex: 1 1;
  padding: 20px;
  width: 100%;
}