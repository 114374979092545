
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
.prayer-times {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.prayer-times div {
    width: 100%;
    background-color: #ecf0f1;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    color: #34495e;
    font-weight: bold;
}


.table{
    display:none
}


.red-border {
    border: 2px solid red;
    padding: 5px;
    border-radius: 5px;
  }
  .carousel-item {
    padding: 20px;
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    text-align: center;
  }
  

  .carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black; /* Set the background color to black */
}


