

.header {
    background-color: #282c34;
    padding: 20px;
  }
  
  .header ul {
    list-style: none;
    display: flex;
    gap: 20px;
  }
  
  .header a {
    color: white;
    text-decoration: none;
  }
  
  .header a:hover {
    text-decoration: underline;
  }
  
  
@media only screen and (max-width: 767px) {
  .header{
      display: none;
  }
  
}